<template>
  <div class="row justify-content-center">
    <customer-header />
    
    <vs-prompt
      color="success"
      title=""
      accept-text="Export Excel"
      cancel-text="ยกเลิก"
      @accept="exportExcelReport"
      @close="close"
      :active.sync="activePromptExport"
    >
      <div class="con-exemple-prompt">
        Export Report Excel
        <br /><br />
        <datepicker
          placeholder="วันที่เริ่มต้น เวลาเข้า"
          :format="DatePickerFormat"
          v-model="startDateReport"
          :click="onClickDate()"
        ></datepicker>
        <br />
        <datepicker
          placeholder="วันที่สิ้นสุด เวลาเข้า"
          :format="DatePickerFormat"
          v-if="startDateReport"
          v-model="endDateReport"
          :disabledDates="disabledDates"
        ></datepicker>
        <br />
        <p style="color: red" align="center">
          Export ได้มากที่สุดแค่ 7 วัน จากวันเริ่มต้น
        </p>
      </div>
    </vs-prompt>
    <vs-prompt
      color="primary"
      title=""
      accept-text="ค้นหา"
      cancel-text="ยกเลิก"
      @accept="listItems(1)"
      @close="close"
      :active.sync="activePromptSearch"
    >
      <div class="con-exemple-prompt">
        ค้นหาข้อมูลข้อมูลการตรวจซ้อนคัน

        <br /><br />
        <v-select
          label="text"
          placeholder="กรุณาเลือก"
          v-model="selected"
          @input="checkOption"
          :options="optionsLists"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />

        <vs-input
          v-if="inputSearch === 1"
          type="text"
          icon="search"
          placeholder="คำค้นหา"
          v-model="searchText"
        />
        <vs-input
          v-if="inputSearch === 2"
          type="date"
          icon="event"
          v-model="searchText"
        />
        <!-- <vs-alert :active="!validName" color="danger" icon="new_releases">
          กรุณากรอกข้อมูลให้ครบถ้วน
        </vs-alert> -->
      </div>
    </vs-prompt>

    <vs-popup
      class="holamundo"
      title="รายละเอียด"
      :active.sync="activePromptDetail"
      v-if="objectDetail"
    >
      <div class="vx-row">
        <div class="vx-col lg:w-1/1 w-full">
          <vx-card class="mb-base">
            <table class="modaltable">
              <tr>
                <td class="font-semibold">รายละเอียด</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.desc | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">เวลาสแกน</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.scanDateTime | formatDate}}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">พิกัดการเดิน</td>
                <td style="padding-left: 50px">
                  <a target="_blank" :href="objectDetail.map_url">
                    <feather-icon
                      icon="MapIcon"
                      title=""
                      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"/></a>
                </td>
              </tr>
              <tr
>
                <td style="">
                  <div
                    style="
                      border: 1px solid #3f4652;
                      padding: 8px;
                      background-color: antiquewhite;
                      margin-top: 10px;max-width:180px; margin-bottom:20px;
                    "
                    v-if="Array.isArray(objectDetail.photos) && objectDetail.photos.length > 0"
                  >
                    <div
                      style="margin-top: 10px;border: 8px solid #3f4652;"
                      :key="indextr_image"
                      v-for="(image, indextr_image) in objectDetail.photos"
                    >
                      <img
                        v-img
                        style="width: 100%;"
                        :src="image"
                        class="img-fluid"
                        :key="indextr_image"
                      />
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="font-semibold">id</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.key | checkString }}
                </td>
              </tr>
            </table>
          </vx-card>
        </div>
      </div>
    </vs-popup>

    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
        <datepicker id="start-date-report" placeholder="กรุณาเลือกวันที่เริ่มต้น"
              :format="DatePickerFormat"
              v-model="startDateReport"
              style="width: 100%;padding-right: 10px;"
              :click="onClickDate()"></datepicker>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
        <datepicker id="start-date-report" placeholder="กรุณาเลือกวันที่สิ้นสุด"
              :format="DatePickerFormat"
              v-model="endDateReport"
              :disabledDates="disabledDates"
              style="width: 100%;padding-right: 10px;"
              :click="onClickDate()"></datepicker>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3">
          <vs-button
              @click="listItems(1)"
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-search"
              :disabled="!canSearch || this.downloadLoading === true"
              style="margin-left: 5px;float: left;"
            >
            ค้นหา
          </vs-button>
          <vs-button
            :loading="downloadLoading"
            @click="exportExcelReport"
            :disabled="!canExport || this.downloadLoading === true"
            color="success"
            type="filled"
            style="margin-left: 5px;float: left;"
            >
            <feather-icon
              icon="DownloadIcon"
              title="Export Excel"
              svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
            />
            Export
          </vs-button>
        <vs-button
              v-if="canSearch"
              @click="resetSearch"
              color="dark"
              type="filled"
              icon-pack="feather"
              style="margin-left: 5px;float: left;"
            >
            Reset
          </vs-button>
      </vs-col>
    </vs-row>
    <vs-card style="margin-top: 10px">
      <div>
        <vs-table stripe :data="items">
          <template slot="thead" style="margin-top: 100px">
            <vs-th>รูป</vs-th>
            <vs-th>รปภ.</vs-th>
            <vs-th>เวลา</vs-th>
            <vs-th>เวลาเริ่มตรวจ</vs-th>
            <vs-th>เวลาตรวจเสร็จ</vs-th>
            <vs-th>รายละเอียด</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].member_verify_photo">
                <img
                  v-img
                  style="
                  object-fit: cover;
                  border-radius:50%;
                  width: 60px;
                  height: 60px;
                  "
                  :src="data[indextr].member_verify_photo"
                  class="img-fluid"
                  :key="indextr"
                />
              </vs-td>
              <vs-td :data="data[indextr].member_name">
                {{ data[indextr].member_name }} <span v-if="data[indextr].is_cancel" style="color:#ae1a1a;">(ยกเลิกการตรวจ)</span>
              </vs-td>
              <vs-td :data="data[indextr].create_date">
                {{ data[indextr].create_date | formatDate }}
              </vs-td>
              <vs-td :data="data[indextr].walk_start_datetime">
                {{ data[indextr].walk_start_datetime | formatDate }}
              </vs-td>
              <vs-td :data="data[indextr].walk_end_datetime">
                {{ data[indextr].walk_end_datetime | formatDate }}
              </vs-td>
              <vs-td :data="data[indextr].walk_end_datetime">
                <a target="_blank" :href="getMapLink(data[indextr].key)">
                 <feather-icon
                          icon="EyeIcon"
                          title="ดูข้อมูลเพิ่มเติม"
                          svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                        /></a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div>
          <br />
          <vs-row>
            <vs-col class="text-right">
              <div class="btn-group">
                <vs-button
                  type="border"
                  style="padding-top: 2px"
                  :disabled="page == 1"
                  @click="previousData"
                  >Prev</vs-button
                >
                <vs-button
                  type="border"
                  :disabled="items.length < show_per_page"
                  @click="nextData"
                  >Next</vs-button
                >
              </div>
            </vs-col>
          </vs-row>

          <!--
          <paginate
            v-model="page"
            :page-count="total"
            :page-range="3"
            :margin-pages="2"
            :click-handler="inputData"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate> -->
        </div>
      </div>
    </vs-card>
    <vs-card style="margin-top: 5px">
      <!-- <h5>จำนวนข้อมูลทั้งหมด {{ totalItems }}</h5> -->
    </vs-card>
  </div>
</template>

<script>
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { db } from "../store/firebaseDb";
const moment = require("moment");
require("moment/locale/th");
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import CustomerHeader from "@/layouts/components/custom/CustomerHeader.vue";

import Paginate from "vuejs-paginate";
import vSelect from "vue-select";

import VueImg from "v-img";
import ExcelJS from 'exceljs';
import axios from 'axios'
Vue.component("paginate", Paginate);

Vue.use(VueCryptojs);
Vue.use(VueCookies);
Vue.use(VueImg);

Vue.component("customer-header", CustomerHeader);

moment.locale("th");
Vue.use(require("vue-moment"), {
  moment,
});

Vue.filter("formatDate", function (value) {
  if (value) {
    const myDate = new Date(value.seconds * 1000); // date object
    return moment(String(myDate)).format("DD/MM/YYYY HH:mm:ss");
  }
  return "-";
});
Vue.filter("cardMissing", function (value) {
  if (value === false) {
    return "มีบัตร";
  } else {
    return "บัตรหาย";
  }
});
Vue.filter("convertCloseHome", function (value) {
  if (value === true) return "ปิดบ้าน";
  return "-";
});
Vue.filter("convertVip", function (value) {
  if (value === true) return "วีไอพี";
  return "-";
});
Vue.filter("checkString", function (value) {
  if (value) return value;
  return "-";
});

export default {
  name: "ManageTransactionsCorp",
  components: {},
  data() {
    return {
      roleInfo: {},
      userInfo: null,
      language: "en",
      languages: lang,
      houseInfo: {},
      items: [],
      itemsTran: [],
      itemsElement: [],
      // pagination
      total: 0, // จำนวนหน้า
      show_per_page: 10,
      page: 1,
      // totalItems: 0, // จำนวนข้อมูลทั้งหมด
      isLoad: true,
      // lastItem: null,
      searchText: "",
      activePromptSearch: false,
      activePromptDetail: false,
      selected: "",
      optionsLists: [
        { text: "เลือกวันที่", value: "create_date" },
        // { text: "เลขที่ติดต่อ", value: "contact_address_name" },
        // { text: "ทะเบียนรถ", value: "license_plate_id" },
      ],
      inputSearch: 0,
      objectDetail: null,
      activePromptExport: false,
      startDateReport: "",
      endDateReport: "",
      DatePickerFormat: "dd/MM/yyyy",
      dateStart: null,
      disabledDates: {},
      itemsReport: [],
      itemsNotWithIn: [],
      additionFields: [],
      customer: {},
   
      headerValRound: [
        "member_name",
        "create_date",
        "walk_start_datetime",
        "walk_end_datetime",
        "member_verify_photo",
      ],
      headerTitleReportRound: [
        "ผลัด",
        "วัน",
        "วันที่เริ่มตรวจ",
        "วันที่ตรวจเสร็จ",
        "รูป",
      ],
      headerValReportRound: [
        "member_name",
        "create_date",
        "walk_start_datetime",
        "walk_end_datetime",
        "member_verify_photo",
      ],

      headerVal: [
        "house_no",
        "name",
        "phone",
        "car_number", 
        "visit_objective_type",
        "date_in",
        "create_date",
        "desc",
        "photos",
      ],
      headerTitleReport: [
        "บ้านเลขที่",
        "ชื่อ",
        "เบอร์โทร",
        "ทะเบียนรถ",
        "ประเภท",
        "เวลาเข้า",
        "เวลาตรวจ",
        "รายละเอียด",
        "รูปแนบ",
      ],
      headerValReport: [
        "house_no",
        "name",
        "phone",
        "car_number", 
        "visit_objective_type",
        "date_in",
        "create_date",
        "desc",
        "photos",
      ],

      downloadLoading: false,
      lastItemsList: {},
      limit: 0,
    };
  },
  computed: {
    validName() {
      return true;
    },
    canSearch(){
      if(this.startDateReport && this.endDateReport){
        return true;
      }
    },
    canExport(){
      if(this.startDateReport && this.endDateReport){
        return true;
      }
    }

  },

  methods: {
    getMapLink(key) {
      return "/mapcaroverlap?id=" + key;
    },
    async parseFile(url) {
          try {
              const fetchResponse = await fetch(url);
              //  const ct = fetchResponse.headers.get("Content-Type");
              // const hd = fetchResponse.header();
              const responseText = await fetchResponse.text();

              //  if ( !this.checkString(responseText) ) {
              //     responseText = "";
              //  }
              return responseText;

          } catch (ex) {
              return "";
          }
      },
    dbRef() {
      const customerName = $cookies.get("customer");
      const _ducumentPath = `customer/${customerName}/scan/data/transaction_park/`;
      return db.collection(_ducumentPath);
    },

    getDbRefWithCondition() {
      let reference = this.dbRef();

      if (this.startDateReport) {
       reference = reference.where("create_date", ">=", this.setDateValue()[0])
       reference = reference.where("create_date", "<=", this.setDateValue()[1])
      }

      reference = reference.orderBy("create_date", "desc");
      const lastItem = this.lastItemsList[`${this.page - 1}`];

      if (lastItem !== undefined) {
        reference = reference.startAfter(lastItem).limit(this.show_per_page);
      }
      if (this.show_per_page !== null) {
        reference = reference.limit(this.show_per_page);
      }
      return reference;
    },

    async listItems({ page = 1 }) {
      this.isLoad = true;
      this.page = page;
      this.getDbRefWithCondition().onSnapshot((snapshotChange) => {
        this.items = [];
        // this.itemsTran = [];
        snapshotChange.forEach(async (doc) => {
          this.lastItemsList[`${page}`] = doc;

          const data = doc.data();
          console.log("data=",data);
          const element = {
            key: doc.id,
            walk_end_datetime: data.walk_end_datetime,
            walk_start_datetime: data.walk_start_datetime,
            create_date: data.create_date,
            member_name: data.member_name,
            is_cancel: data.is_cancel,
            member_verify_photo: data.member_verify_photo,
          };

          this.itemsTran[doc.id] = [];
          this.getTransaction(doc.id);

          this.items.push(element);
          this.isLoad = false;
        });
      });
    },

    async getTransaction(id) {
      const list = [];

      await this.dbRef()
        .doc(id)
        .collection("transaction_car")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            list[list.length] = {
              key: doc.id,
              car_number: data.car_number,
              create_date: data.create_date,
              desc: data.desc,
              is_ok: data.is_ok,
              is_out_of_limit: data.is_out_of_limit,
              is_visitor: data.is_visiter,
              photos: data.photos,
              type_over_lap: data.transactions_ref ? "transactions" : "house",
              ref: data.transactions_ref ? data.transactions_ref : data.house_ref,
              status_type: data.status_type
            };
            this.itemsTran[id] = list;
          });
        });
    },

    isPrefixAddress(prefix_address, contact_address_name) {
      return prefix_address
        ? prefix_address + " / " + contact_address_name
        : contact_address_name;
    },

    isEmpty(data) {
      return data === undefined || data === null || data === "";
    },

    previousData: function () {
      this.page = this.page - 1;
      this.listItems({ page: this.page });
    },

    nextData: function () {
      this.page = this.page + 1;
      this.listItems({ page: this.page });
    },

    inputData: function (value) {
      this.page = value;
      this.listItems({ page: this.page });
    },

    close() {
      this.selected = "";
      this.searchText = "";
      this.inputSearch = 0;
    },

    openAddSearch() {
      this.selected = "";
      this.searchText = "";
      this.inputSearch = 0;
      this.activePromptSearch = true;
    },

    checkOption() {
      this.searchText = "";
      if (this.selected.value === "create_date") {
        this.inputSearch = 2;
      } else {
        this.inputSearch = 1;
      }
    },

    setDateValue() {
      const dateStart = this.startDateReport;
      const dateEnd = this.endDateReport;
      const date_formatS = new Date(dateStart);
      const date_formatE = new Date(dateEnd);
      const date_inS = new Date(date_formatS - 60 * 60 * 1000);
      const date_inE = new Date(date_formatE - 60 * 60 * 1000);

      date_inS.setHours(0, 0, 0, 0);
      const value = date_inS;

      date_inE.setDate(date_inE.getDate() + 1);
      date_inE.setHours(0, 0, 0, 0);
      const value2 = date_inE;
      return [value, value2];
    },

    getExportFileName() {
      const customerName = $cookies.get("customer");
      const startDate = moment(new Date(this.startDateReport)).format(
        "DDMMYYYY"
      );
      const endDate = moment(new Date(this.endDateReport)).format("DDMMYYYY");
      return customerName + "-" + startDate + "-" + endDate;
    },

    strDate(begin, end) {
      const d = Math.trunc((end - begin) / 60 / 60 / 24);
      const h = Math.trunc((end - begin) / 60 / 60) % 24;
      const m = Math.trunc((end - begin) / 60) % 60;
      const s = (end - begin) % 60;
      let msg = "";
      if (d > 0) {
        msg += d + " วัน ";
      }
      if (h > 0) {
        msg += h + " ชั่วโมง ";
      }
      if (m > 0) {
        msg += m + " นาที ";
      }
      if (s > 0) {
        msg += s + " วินาที ";
      }
      if (s <= 0) {
        msg += s + " วินาที ";
      }
      return msg;
    },

    async resetSearch() {
      this.searchText = "";
      this.items = [];
      this.total = 0;
      this.page = 1;
      this.isLoad = true;
      this.total = 0;
      this.startDateReport ='';
      this.endDateReport ='';
      // this.totalItems = 0;
      // this.lastItem = null;
      this.selected = "";
      this.listItems(1);
    },

    openDetail(objectData) {
      this.objectDetail = null;
      this.activePromptDetail = true;
      this.objectDetail = objectData;
    },

    openModalExcel() {
      this.startDateReport = null;
      this.endDateReport = null;
      this.activePromptExport = true;
    },

    async onClickDate() {
      if (this.startDateReport) {
        if (this.startDateReport !== this.dateStart) {
          this.dateStart = this.startDateReport;
          this.disabledDates = {
            to: new Date(
              new Date(this.startDateReport - 60 * 60 * 1000) - 8640000
            ),
            from: new Date(
              new Date(this.startDateReport - 60 * 60 * 1000).setDate(
                new Date(this.startDateReport - 60 * 60 * 1000).getDate() + 8
              ) - 8640000
            ),
          };
        }
      }
    },

    exportExcelReport() {
      this.downloadLoading = true;
      this.$vs.loading()
      this.dbRef()
        .where("create_date", ">=", this.setDateValue()[0])
        .where("create_date", "<=", this.setDateValue()[1])
        .orderBy("create_date", "desc")
        .get()
        .then(async (query) => {
          this.itemsReport = [];

          // const data = doc.data();

          for (let i = 0; i < query.docs.length; i++) {
            const doc = query.docs[i];
            const data = doc.data();

            let date_full = "";

            if (data.walk_start_datetime && data.walk_end_datetime) {
              date_full = this.strDate(
                data.walk_start_datetime.seconds,
                data.walk_end_datetime.seconds
              );
            }

            this.itemsTran[doc.id] = [];
            await this.getTransaction(doc.id);

            const transactionItems = this.itemsTran[doc.id];

            if (transactionItems.length === 0) {
              const element = {
                key: doc.id,
                create_date: Vue.filter("formatDate")(data.create_date),
                member_name: data.is_cancel ? (data.member_name + " (ยกเลิกการตรวจ)") : data.member_name,
                member_verify_photo: data.member_verify_photo,
                walk_end_datetime: Vue.filter("formatDate")(
                  data.walk_end_datetime
                ),
                walk_start_datetime: Vue.filter("formatDate")(
                  data.walk_start_datetime
                ),
              };

              this.itemsReport.push(element);
            } else {
              for (let j = 0; j < transactionItems.length; j++) {
                const tItem = transactionItems[j];
                

                if(tItem.ref === undefined || tItem.ref === null){
                   const element = {
                    key: doc.id,
                    create_date: Vue.filter("formatDate")(data.create_date),
                    member_name: data.is_cancel ? (data.member_name + " (ยกเลิกการตรวจ)") : data.member_name,
                    member_verify_photo: data.member_verify_photo,
                    walk_end_datetime: Vue.filter("formatDate")(
                      data.walk_end_datetime
                    ),
                    walk_start_datetime: Vue.filter("formatDate")(
                      data.walk_start_datetime
                    ),
                    house_no: this.isEmpty(tItem.house_no) ? '-' : tItem.house_no,
                    name: '-',
                    phone: '-',
                    visit_objective_type: '-',
                    date_in: '-',
                    car_number: tItem.car_number,
                    desc: tItem.desc ? tItem.desc : '-',
                    photos: tItem.photos.length > 0 ? tItem.photos : '-',
                    status_type: tItem.status_type,
                  };
                  this.itemsNotWithIn.push(element)             
                }else{
                  const longPath = tItem.ref.path;
                  const pathStrSplit = longPath.split("/");
                  const fileName = pathStrSplit.pop();
                  const customerName = $cookies.get("customer");
                  const _ducumentPath = `customer/${customerName}/${pathStrSplit[2]}`;
                  const ref_data = await db
                    .collection(_ducumentPath)
                    .doc(fileName)
                    .get();
                  const getRef = ref_data.data();
                  console.log("getRef11",getRef);

                  if(getRef !== undefined){
                    let house_no = getRef.house_no;
                    if(tItem.type_over_lap === 'transactions'){
                      house_no = getRef.contact_address_name;
                    }
                    house_no = this.isEmpty(house_no) ? '-' : house_no ;
                    const element = {
                      key: doc.id,

                      member_verify_photo: data.member_verify_photo,
                      member_name: data.is_cancel ? (data.member_name + " (ยกเลิกการตรวจ)") : data.member_name,
                      create_date: Vue.filter("formatDate")(data.create_date),
                      walk_end_datetime: Vue.filter("formatDate")(
                        data.walk_end_datetime
                      ),
                      walk_start_datetime: Vue.filter("formatDate")(
                        data.walk_start_datetime
                      ),

                      house_no: house_no,
                      name: tItem.type_over_lap === 'house' ? getRef.name : '-',
                      phone: tItem.type_over_lap === 'house' ? getRef.phone : '-',
                      car_number: tItem.car_number,
                      desc: tItem.desc ? tItem.desc : '-',
                      photos: tItem.photos.length > 0 ? tItem.photos : '-',

                      type_over_lap: tItem.type_over_lap,
                      is_out_of_limit: tItem.is_out_of_limit,
                      status_type: tItem.status_type,

                      visit_objective_type: getRef.visit_objective_type !== undefined ? getRef.visit_objective_type : '-',       
                      date_in: tItem.type_over_lap === 'transactions' ? Vue.filter("formatDate")(getRef.date_in) : '-',
                    };
                    this.itemsReport.push(element);
                  }
                }
              }
            }
          }
          this.downloadLoading = false;
          this.$vs.loading.close()
          if (this.itemsReport.length) {
            const workbook = new ExcelJS.Workbook();
            const round = workbook.addWorksheet('รอบการตรวจ');

            let headerLabelRound = [];
            this.headerValReportRound.forEach((item,index)=>{
              let maxLength = 15
              let cellLength = this.headerTitleReportRound[index].length;
              if (cellLength > maxLength) {
                maxLength = cellLength;
              }
              const header = {
                header:this.headerTitleReportRound[index],
                key:item,
                width: maxLength
              }
              headerLabelRound.push(header);
            });

            round.columns = headerLabelRound;
            let headerRowRound = round.getRow(1);
            headerRowRound.height = 30;
            headerRowRound.eachCell((cell) => {
                cell.font = {
                  bold: true,
                  color: { argb: 'FFFFFFFF' },
                  size: 14
                };
                cell.fill = {
                  type: 'pattern',
                  pattern:'solid',
                  fgColor:{argb:'FF0000FF'}
                };
              });

            const column = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

            const duplicatedRound = this.itemsReport.map(({ key }) => key);
            const filteredRound = this.itemsReport.filter(({ key }, index) =>!duplicatedRound.includes(key, index + 1));

            for(let [index,item] of filteredRound.entries())
            {
              let userImage = item.member_verify_photo;
              const dateCreate = moment(new Date(this.startDateReport)).format("DD/MM/YYYY");
              const timeCreate = moment(new Date(this.startDateReport)).format("HH:mm");
              item.create_date = dateCreate;
              item.time = timeCreate;

              let row =  round.addRow(item);
              round.getRow(index+2).font = {size: 14 };

              if(userImage !== ""){
                try {
                const urlProfile = decodeURI(userImage)
                const indexRow = index+2;
                const field = `${column[4]}${indexRow}`;
                round.getCell(field).value = null;
                round.getCell(field).value = {
                  text: 'รูปผู้ตรวจ',
                  hyperlink: urlProfile,
                  tooltip: 'open'
                }
                round.getCell(field).font = {underline: true,color: { argb: 'FF0000FF' }};
                } catch (e) {
                    console.error(e);
                }
              }
            }

            const dataAll = this.itemsReport.concat(this.itemsNotWithIn)
            const dataSort = dataAll.reduce((acc, cur) =>{
              const category = cur.walk_start_datetime;
                if (!acc[category]) {
                  acc[category] = [];
                }
                acc[category].push(cur);
                return acc;
              }, {})

              for(let i=0; i < this.itemsReport.length; i++){
                const v = this.itemsReport[i]
           /     console.log("v1=",v);
                if( v.type_over_lap === "transactions" && v.status_type === 4){
                  console.log("v11=",v);
                }
              }

              for(let i=0; i < dataSort.length; i++){
                const v = dataSort[i]
                console.log("v2=",v);
                if( v.type_over_lap === "transactions" && v.status_type === 4){
                  console.log("v22=",v);
                }
              }
              // for(let [indexCarVisitor,itemCarVisitor] of Object.values(dataSort)[i].filter((v)=> v.type_over_lap === "transactions" && v.status_type === 4 ).entries())
              // {
            
            for(let i=0; i < Object.keys(dataSort).length; i++){
              const split = (Object.keys(dataSort)[i]).split('/')
              const splitDate = split[2].split(" ")
              const splitTime = splitDate[1].split(':')
              const wb = workbook.addWorksheet(split[0]+"_"+split[1]+"_"+splitDate[0]+" "+splitTime[0]+"_"+splitTime[1]+"_"+splitTime[2]);

              const overLapDataList = [] 
              for(let [indexCarOverLap,itemCarOverLap] of Object.values(dataSort)[i].filter((v)=> v.status_type === 2 ).sort((a, b)=> a.house_no - b.house_no).entries())
              {
                const dateCreate = moment(new Date(this.startDateReport)).format("DD/MM/YYYY");
                const timeCreate = moment(new Date(this.startDateReport)).format("HH:mm");
                // itemCarOverLap.create_date = dateCreate;
                itemCarOverLap.time = timeCreate;

                overLapDataList.push(itemCarOverLap);
              }
              let headerMainOver = [{ house_no: "รายงานตรวจรถเกินสิทธิ์" + " "+ "รอบ"+ " " + Object.keys(dataSort)[i],name: "", phone: "", car_number: "",visit_objective_type: "",date_in: "", create_date:"", desc: "", photos: ""}]
              let headerNameOver = [{ house_no: "รถจอดเกินสิทธิ์"+ " " + "รวม(" + Object.values(dataSort)[i].filter((v)=> v.status_type === 2).length +")",name: "", phone: "", car_number: "",visit_objective_type: "",date_in: "", create_date:"", desc: "", photos: ""}]
              let headerOverLap = [{ house_no:"บ้านเลขที่", name: "ชื่อ", phone: "เบอร์โทร", car_number: "ทะเบียนรถ",visit_objective_type: "ประเภท",date_in: "เวลาเข้า", create_date:"เวลาตรวจ", desc: "รายละเอียด", photos: "รูปแนบ"}]
              let startRow = this.excelRender(wb,headerMainOver.concat(headerNameOver.concat(headerOverLap.concat(overLapDataList))),this.headerVal,this.headerTitleReport,1)

              const houseDataList = [] 
              for(let [indexCarHouse,itemCarHouse] of Object.values(dataSort)[i].filter((v)=> v.type_over_lap === "house" && v.status_type === 1 ).sort((a, b)=> a.house_no - b.house_no).entries())
              {
                const dateCreate = moment(new Date(this.startDateReport)).format("DD/MM/YYYY");
                const timeCreate = moment(new Date(this.startDateReport)).format("HH:mm");
                // itemCarHouse.create_date = dateCreate;
                itemCarHouse.time = timeCreate;

                houseDataList.push(itemCarHouse);
              }
              let headerMainHouse = [{ house_no: "",name: "", phone: "", car_number: "",visit_objective_type: "",date_in: "", create_date:"", desc: "", photos: ""}]
              let headerNameHouse = [{ house_no: "รถจอดตามสิทธิ์"+ " " + "รวม(" + Object.values(dataSort)[i].filter((v)=> v.type_over_lap === "house" && v.status_type === 1 ).length +")",name: "", phone: "", car_number: "",visit_objective_type: "",date_in: "", create_date:"", desc: "", photos: ""}]
              let headerHouse = [{ house_no:"บ้านเลขที่", name: "ชื่อ", phone: "เบอร์โทร", car_number: "ทะเบียนรถ",visit_objective_type: "ประเภท",date_in: "เวลาเข้า", create_date:"เวลาตรวจ", desc: "รายละเอียด", photos: "รูปแนบ"}]
              startRow = this.excelRender(wb,headerMainHouse.concat(headerNameHouse.concat(headerHouse.concat(houseDataList))),this.headerVal,this.headerTitleReport,startRow)

              const notWithInDataList = [] 
              for(let [indexCarNotWithIn,itemCarNotWithIn] of Object.values(dataSort)[i].filter((v)=> v.status_type === 6).entries())
              {
                const dateCreate = moment(new Date(this.startDateReport)).format("DD/MM/YYYY");
                const timeCreate = moment(new Date(this.startDateReport)).format("HH:mm");
                // itemCarNotWithIn.create_date = dateCreate;
                itemCarNotWithIn.time = timeCreate;

                notWithInDataList.push(itemCarNotWithIn);
              }
              let headerMainNot = [{ house_no: "",name: "", phone: "", car_number: "",visit_objective_type: "",date_in: "", create_date:"", desc: "", photos: ""}]
              let headerNameNotWithIn = [{ house_no: "รถไม่มีในระบบ" + " " + "รวม(" + Object.values(dataSort)[i].filter((v)=> v.status_type === 6).length +")",name: "", phone: "", car_number: "",visit_objective_type: "",date_in: "", create_date:"", desc: "", photos: ""}]
              let headerNotWithIn = [{ house_no:"บ้านเลขที่", name: "ชื่อ", phone: "เบอร์โทร", car_number: "ทะเบียนรถ",visit_objective_type: "ประเภท",date_in: "เวลาเข้า", create_date:"เวลาตรวจ", desc: "รายละเอียด", photos: "รูปแนบ"}]
              startRow = this.excelRender(wb,headerMainNot.concat(headerNameNotWithIn.concat(headerNotWithIn.concat(
                notWithInDataList.filter((obj, index) => {
                  return index === notWithInDataList.findIndex(o => obj.key === o.key && obj.car_number === o.car_number);
                })
              ))),this.headerVal,this.headerTitleReport,startRow)
             
             const visitorDataList = [] 
             console.log("dataSort=",dataSort);
              for(let [indexCarVisitor,itemCarVisitor] of Object.values(dataSort)[i].filter((v)=> v.type_over_lap === "transactions" && v.status_type === 4 ).entries())
              {
                const dateCreate = moment(new Date(this.startDateReport)).format("DD/MM/YYYY");
                const timeCreate = moment(new Date(this.startDateReport)).format("HH:mm");
                // itemCarVisitor.create_date = dateCreate;
                itemCarVisitor.time = timeCreate;
                // itemCarVisitor.house_no = "xx";

                visitorDataList.push(itemCarVisitor);
              }
              console.log("visitorDataList=",visitorDataList);

              let headerMainVisitor = [{ house_no: "",name: "", phone: "", car_number: "",visit_objective_type: "",date_in: "", create_date:"", desc: "", photos: ""}]
              let headerNameVisitor = [{ house_no: "รถผู้มาติดต่อ" + " " + "รวม(" + Object.values(dataSort)[i].filter((v)=> v.type_over_lap === "transactions" && v.status_type === 4 ).length +")",name: "", phone: "", car_number: "",visit_objective_type: "",date_in: "", create_date:"", desc: "", photos: ""}]
              let headerVisitor = [{ house_no:"บ้านเลขที่", name: "ชื่อ", phone: "เบอร์โทร", car_number: "ทะเบียนรถ",visit_objective_type: "ประเภท",date_in: "เวลาเข้า", create_date:"เวลาตรวจ", desc: "รายละเอียด", photos: "รูปแนบ"}]
              startRow = this.excelRender(wb,headerMainVisitor.concat(headerNameVisitor.concat(headerVisitor.concat(visitorDataList))),this.headerVal,this.headerTitleReport,startRow)
            }
            
             // Generate the Excel file
            const buffer = await workbook.xlsx.writeBuffer();

            // Create a Blob from the buffer
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Create a link element
            const link = document.createElement('a');
            const customerName = $cookies.get("customer");
            // Set the href and download attributes of the link
            link.href = URL.createObjectURL(blob);
            // link.download = customerName+'.xlsx';
            link.download = this.getExportFileName()+'.xlsx';

            // Append the link to the body
            document.body.appendChild(link);

            // Trigger a click on the link
            link.click();

            // Remove the link from the body
            document.body.removeChild(link);

            } else {
              this.$vs.notify({
                color: "warning",
                title: "Export Excel Report",
                text: "ไม่พบข้อมูล !",
              });
            }
        });
      
    },
    async getImageTobase(url){
      if(!this.isValidUrl(url)){
        return ""
      }else{
        return  await axios.get(url, {
                      responseType: "arraybuffer"
                    })
                    .then(response =>
                      Buffer.from(response.data, "binary").toString("base64")
                    );
      }
    },
    isValidUrl(string) {
      try {
        new URL(string);
        return true;
      } catch (err) {
        return false;
      }
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          const reportValue = v[j];
          if (this.isEmpty(reportValue)) return "-";
          if (Array.isArray(reportValue)) return reportValue.join();

          return reportValue;
        })
      );
    },
    
    excelRender(wb,dataList,headerVal,headerReport,startRow){
      let headerMain = []
        headerVal.forEach((item,index)=>{
          let maxLength = 15
          let cellLength = headerReport[index].length;
          if (cellLength > maxLength) {
            maxLength = cellLength;
          }
          const header = {
            header:headerReport[index],
            key:item,
            width: maxLength
          }
          headerMain.push(header);
        });
        wb.columns = headerMain;

        let headerRow = wb.getRow(startRow);
        headerRow.height = 15;
        headerRow.eachCell((cell) => {
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFFFF' },
            size: 14
          };
          cell.fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFFFF'}
          };
        });

      const newRows = wb.addRows(dataList);
      const column = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
      let indexRow = startRow;
      let indexRowMerge = startRow+2
      for(let [index,item] of newRows.entries()){
        const photoCell = item._cells[8]
        const photoList = photoCell._value.model.rawValue
        if(photoList){
          let endColumn = ''
          for(const [indexPhoto,photo] of photoList.entries()){
            const url = decodeURI(photo)
            try {
              const indexRowSet = startRow+index+1
              const positionPhoto = 8+indexPhoto;
              endColumn = column[positionPhoto]
              const field = `${endColumn}${indexRowSet}`;
              wb.getCell(field).value = {
                text: `รูปแนบ ${indexPhoto+1}`,
                hyperlink: url,
                tooltip: 'open'
              }
              wb.getCell(field).font = {underline: true,color: { argb: 'FF0000FF' }};
              } catch (e) {
                console.error(e);
            }
          }
          indexRow++
          let data = 'I'+indexRowMerge+':'+endColumn+indexRowMerge
        }
        // wb.mergeCells('I'+indexRowMerge+':'+endColumn+indexRowMerge);
      }

      let headerRowMain = wb.getRow(newRows[0]._number)
      headerRowMain.height = 22;
      headerRowMain.eachCell((cell)=> {
        cell.font = {
          bold: true,
          color: { argb: '00000000' },
          size: 18
        };
        cell.fill = {
          type: 'pattern',
          pattern:'solid',
          fgColor:{argb:'F1C40F'}
        };
      })

      let headerRowName = wb.getRow(newRows[1]._number)
      headerRowName.height = 18;
      headerRowName.eachCell((cell)=> {
        cell.font = {
          bold: true,
          color: { argb: '00000000' },
          size: 14
        };
        cell.fill = {
          type: 'pattern',
          pattern:'solid',
          fgColor:{argb:'FFFFFF00'}
        };
      })
      let headerRows = wb.getRow(newRows[2]._number)
      headerRows.height = 18;
      headerRows.eachCell((cell)=> {
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFFFF' },
          size: 12
        };
        cell.fill = {
          type: 'pattern',
          pattern:'solid',
          fgColor:{argb:'FF0000FF'}
        };
      })
      return startRow+dataList.length+1
    },
  },
  components: {
    Datepicker,
    "v-select": vSelect,
  },
  watch: {},
  async created() {
    this.roleInfo = $cookies.get("roleInfo");
    this.userInfo = $cookies.get("userInfo");

    if ($cookies.get("userInfo") === null) {
      this.$router.push("/pages/login").catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    }

    this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
    const parameters = this.$route.query;

    const home = $cookies.get("home");

    this.houseInfo = $cookies.get("houseinfo");

    // await this.getCustomer();
    this.listItems({ page: this.page });
  },
};
</script>

<style>
.con-exemple-prompt {
  padding: 10px;
  padding-bottom: 0px;
}
.vs-input {
  width: 100%;
  margin-top: 10px;
}
.page-item {
}
.demo-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.modaltable tr td:first-child {
  min-width: 100px;
}

.fullscreen-v-img {
  z-index: 999999999 !important;
}

.notNorlmalCase {
  background-color: #962525 !important;
  color: #fff;
  min-width: 100px;
}

.norlmalCase {
  background-color: #0d9c44 !important;
  color: #fff;
  min-width: 150px;
}
</style>
